<template>
  <div :class="`${$store.state.dataViewer.viewType}-view`">
    <data-table
      :headers="headers"
      :data="tableData"
      :start-index="0"
      :page-size="50"
      :current-page="1"
      :total="tableData.length"
      :sort-field="''"
      :sort-order="''"
      :parent-name="''"
      :floating-scrollbar-options="floatingScrollbarOptions" 
      class="analytics-line-table"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DataTable from '~/components/data-viewers/DataViewer/DataTable'
export default {
  name: 'AnalyticsLineTable',
  components: { DataTable },
  props: {
    lineChart: {
      type: Object,
      default: function() {
        return {}
      }
    },
    tableData: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      floatingScrollbarOptions: {
        zIndex: 2,
        offsetBottom: 0
      }
    }
  },
  computed: {
    ...mapState('analytics', ['measurement', 'measurementOptions']),
    headers() {
      const fixedHeader = [
        {
          id: 'index',
          text: '#'
        },
        {
          id: 'timestamp',
          text: 'Time'
        }
      ]

      const dynamicHeader = this.lineChart.legend.map(({ label }) => ({
        id: label,
        text:
          label === 'All' ? this.measurementOptions[this.measurement] : label
      }))
      return [...fixedHeader, ...dynamicHeader]
    }
  }
}
</script>
