<template>
  <div class="chart-container">
    <div class="chart-head">
      <h2>{{ chartTitle }} {{ fieldAnalyzedTitle }}</h2>
      <info-navigation
        :disabled-date-picker="true"
        :stats="[]"
        :stat="selectedStat"
        :date-range="dateRange"
        :interval-scale="$store.state.dateInterval"
        :disabled="lineChartTableData.length === 0"
        style="padding: 0"
        @dateInterval="dateInterval"/>
    </div>

    <div class="chart-main">
      <analytics-line-chart
        :update-visible-data="updateVisibleData"
        :line-chart="lineChartData" />
    </div>

    <analytics-line-table
      v-if="lineChartTableData.length !== 0"
      :line-chart="lineChartData"
      :table-data="lineChartTableData"/>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import AnalyticsLineChart from './AnalyticsLineChart'
import AnalyticsLineTable from './AnalyticsLineTable'
import InfoNavigation from '~/components/common/InfoNavigation/InfoNavigation'

export default {
  name: 'AnalyticsLineChartSection',
  components: { AnalyticsLineChart, InfoNavigation, AnalyticsLineTable },
  data() {
    return {
      updateVisibleData: true
    }
  },
  computed: {
    ...mapGetters('analytics', [
      'chartTitle',
      'lineChartData',
      'lineChartTableData'
    ]),
    ...mapState(['dateRange']),
    ...mapState('analytics', ['measurement', 'lineChartField', 'fieldOptions']),
    selectedStat() {
      return ''
    },
    fieldAnalyzedTitle() {
      if (this.lineChartField === 'none') return ''

      return `by ${this.fieldOptions[this.lineChartField]}`
    },
    fieldAndMeasurements() {
      return {
        measurement: this.measurement,
        lineChartField: this.lineChartField
      }
    }
  },
  watch: {
    fieldAndMeasurements() {
      this.updateVisibleData = true
    }
  },
  methods: {
    ...mapActions('analytics', ['fetchAnalyticsData']),
    dateInterval(interval) {
      this.updateVisibleData = false
      this.$store.commit('setDateInterval', interval)
      this.fetchAnalyticsData({ action: 'scaleRefinement' })
    }
  }
}
</script>
