<template>
  <div class="analytics-pie-chart-card">
    <analytics-pie-chart
      ref="pieChart"
      :chart-data="chartData"
      class="pie-chart"/>
    <pie-chart-legend
      :legend="chartData.legend"
      @hover="onLegendHover"/>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import AnalyticsPieChart from './AnalyticsPieChart'
import PieChartLegend from '~/components/charts/PieChart/PieChartLegend'
import { chart } from '~/config/config'
import pattern from 'patternomaly'

export default {
  name: 'AnalyticsPieChartCard',
  components: {
    AnalyticsPieChart,
    PieChartLegend
  },
  computed: {
    chartData() {
      const MIN_PERCENTAGE = 0.03
      const pieColorLists = chart.pie.colors
      let pieColorIndex = 0
      let availableColors = pieColorLists[pieColorIndex].concat()

      const colors = []
      const data = []
      const labels = []
      const others = []
      const legend = []

      let othersValue = 0

      const analyticsData = this.$store.getters[
        'analytics/allAnalyticsData'
      ].concat()

      const cleanFunction = this.$store.getters['analytics/cleanFunction']
      const { isResultsClean, field } = this.$store.state.analytics

      const sum = analyticsData.reduce((sum, item) => sum + item.value, 0)

      if (analyticsData.length > 0) {
        analyticsData.forEach(item => {
          const { id, value, name: label } = item
          const percent = value / sum

          // Add this value to all others
          if (
            percent < MIN_PERCENTAGE ||
            (isResultsClean && !cleanFunction(item))
          ) {
            othersValue += value
            others.push({ label, value, percent })
            return
          }

          // Handle colors
          if (availableColors.length === 0) {
            pieColorIndex =
              pieColorIndex + 1 >= pieColorLists.length ? 0 : pieColorLists + 1
            availableColors = pieColorLists[pieColorIndex].concat()
          }

          const colorIndex = this.getHash(label) % availableColors.length
          const color = availableColors.splice(colorIndex, 1)
          colors.push(color)
          data.push(value)
          labels.push(label)
          legend.push({
            label,
            ...(['consignee', 'shipper'].includes(field)
              ? { link: `/company/${id}/overview` }
              : {}),
            color
          })
        })

        // handle all others
        if (othersValue > 0) {
          const labelName = this.getOtherLabel()
          labels.push(labelName)
          colors.push(
            pattern.draw('diagonal', chart.pie.otherColor, '#80b1ff', 10)
          )
          legend.push({ label: labelName, color: chart.pie.otherColor })
          data.push(othersValue)
        }
      }

      return {
        legend,
        labels,
        colors,
        others,
        data,
        datasets: [
          {
            data,
            backgroundColor: colors,
            hoverBorderWidth: 0,
            borderWidth: 0,
            hoverBackgroundColor: colors,
            hoverBorderColor: colors
          }
        ]
      }
    }
  },
  watch: {
    chartData(data) {
      this.setChartLegend(data.legend)
    }
  },
  mounted() {
    this.setChartLegend(this.chartData.legend)
  },
  methods: {
    ...mapMutations('analytics', ['setChartLegend']),
    onLegendHover(index) {
      if (!this.$refs.pieChart.isRendered) {
        return
      }

      const chart = this.$refs.pieChart.$data._chart
      const data = chart.getDatasetMeta(0).data
      const slice =
        index === -1
          ? null
          : index < data.length
            ? data[index]
            : data[data.length - 1]

      const active = slice ? [slice] : []
      this.$refs.pieChart.activeElements = active
      chart.tooltip._active = active
      chart.tooltip.update(true)
      chart.render()
    },
    getOtherLabel() {
      const { field } = this.$store.state.analytics
      let otherLabel = ''
      switch (field) {
        case 'country':
          otherLabel = 'Countries'
          break
        case 'usPort':
          otherLabel = 'U.S. Ports'
          break
        case 'foreignPort':
          otherLabel = 'Foreign Ports'
          break
        case 'consignee':
        case 'shipper':
        case 'state':
        default:
          otherLabel = `${field}s`
      }
      return `All Other ${otherLabel}`
    }
  }
}
</script>
