<template>
  <div>
    <div
      class="chart-container bar-chart">
      <div class="chart-head">
        <h2>{{ chartTitle }}</h2>

        <div class="total-measurement">
          <strong>{{ convertToTwoDigitDecimal(totalShipmentsCount) }}</strong> Total
          {{ units }}
        </div>
      </div>

      <div class="chart-main">
        <analytics-bar-chart
          :paginated-data="paginatedData"
          :ticks-count="ticksCount"
          @layout="layout = $event"/>

        <analytics-bar-chart-labels
          :layout="layout"
          :data="paginatedData"/>

        <analytics-chart-pagination
          :ticks-count="ticksCount"
          :total="cleanedAnalyticsData.length"
          v-model="page"/>
      </div>
    </div>

    <analytics-table/>

  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import { convertToTwoDigitDecimal } from '~/utils/number'
import AnalyticsBarChart from './AnalyticsBarChart'
import AnalyticsBarChartLabels from './AnalyticsBarChartLabels'
import AnalyticsChartPagination from './AnalyticsChartPagination'
import AnalyticsTable from '~/components/analytics/AnalyticsTableSection/AnalyticsTable'

export default {
  name: 'AnalyticsBarChartSection',
  components: {
    AnalyticsChartPagination,
    AnalyticsBarChart,
    AnalyticsBarChartLabels,
    AnalyticsTable
  },
  data() {
    return {
      page: 1,
      ticksCount: 10,
      layout: {}
    }
  },
  computed: {
    ...mapGetters('analytics', ['chartTitle', 'cleanedAnalyticsData']),
    isResultsClean() {
      return this.$store.state.analytics.isResultsClean
    },
    paginatedData() {
      const startIndex = (this.page - 1) * this.ticksCount
      return this.cleanedAnalyticsData.slice(
        startIndex,
        startIndex + this.ticksCount
      )
    },
    totalShipmentsCount() {
      return this.cleanedAnalyticsData.reduce(
        (totalCount, item) => totalCount + item.value,
        0
      )
    },
    units() {
      const measurement = this.$store.state.analytics.measurement
      if (measurement === 'calculated_teu') return 'TEU'
      return measurement
    }
  },
  watch: {
    cleanedAnalyticsData() {
      this.page = 1
    },
    isResultsClean() {
      this.page = 1
    }
  },
  methods: {
    convertToTwoDigitDecimal
  }
}
</script>
