<script>
import { Bar } from 'vue-chartjs'
import analyticsChartMixin from '../analyticsChartMixin.js'
import { chart } from '~/config/config'

export default {
  name: 'AnalyticsBarChart',
  extends: Bar,
  mixins: [analyticsChartMixin],
  props: {
    ticksCount: {
      type: Number,
      required: true
    },
    paginatedData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      axisCharCount: 0
    }
  },
  computed: {
    chartData() {
      const colors = chart.bar.colors
      const data = []
      const labels = []
      let max = 0

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.paginatedData.forEach(({ name, value }) => {
        labels.push(name)
        data.push(value)

        if (value > max) {
          max = value
        }
      })

      return {
        labels,
        max,
        datasets: [
          {
            data,
            backgroundColor: colors,
            hoverBorderWidth: 6,
            hoverBackgroundColor: colors,
            hoverBorderColor: colors
          }
        ]
      }
    },
    chartOptions() {
      const self = this
      const { $numeral } = self
      const { max } = self.chartData

      return {
        labelTooltip: true,
        legend: { display: false },
        scales: {
          xAxes: [
            {
              display: false
            }
          ],
          yAxes: [
            {
              position: 'right',
              ticks: {
                beginAtZero: true,
                callback(value) {
                  return $numeral(value).format('0a')
                }
              }
            },
            {
              position: 'left',
              ticks: {
                suggestedMax: max,
                beginAtZero: true,
                callback(value) {
                  return $numeral(value).format('0a')
                }
              }
            }
          ]
        }
      }
    }
  },
  beforeMount() {
    const self = this
    this.addPlugin({
      afterDraw(chart) {
        const databaseMeta = chart.getDatasetMeta(0)
        const padding = databaseMeta.controller._ruler.scale.left
        self.$emit('layout', { padding })
      }
    })
  }
}
</script>
