<template>
  <div>
    <shipments-chart
      :data="dynamicChartData.chartData"
      :labels="lineChart.labels"
      :gradient="true"
      :tooltip-text="tooltipText"
      :interval="$store.state.dateInterval"
      :display-empty="isIncompleteDataShown"
      :autoscale-axis="autoscaleYAxis"
      class="analytics-line-chart"/>

    <line-chart-legend
      :legend="dynamicChartData.chartLegend"
      @toggle-data="hideDataSet"
      @select="selectDataset" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { startCase } from 'lodash'
import ShipmentsChart from '~/components/charts/ShipmentsChart'
import LineChartLegend from './LineChartLegend'

export default {
  name: 'AnalyticsLineChart',
  components: { ShipmentsChart, LineChartLegend },
  props: {
    lineChart: {
      type: Object,
      required: true
    },
    updateVisibleData: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      hiddenDataset: []
    }
  },
  computed: {
    ...mapState('analytics', [
      'measurement',
      'selectedDatasets',
      'lineChartField'
    ]),
    ...mapState(['autoscaleYAxis', 'isIncompleteDataShown']),
    tooltipText() {
      let text = this.measurement
      if (text === 'calculated_teu') {
        text = 'TEU'
      } else {
        text = startCase(text)
      }

      return [text]
    },
    dynamicChartData() {
      const chartData = this.lineChart.data.map(this.appendVisibility)
      const chartLegend = this.lineChart.legend.map(this.appendVisibility)

      return { chartData, chartLegend }
    }
  },
  watch: {
    lineChartField() {
      if (this.updateVisibleData) this.hiddenDataset = []
    }
  },
  methods: {
    ...mapActions('analytics', ['selectDataset']),
    hideDataSet(dataName) {
      const index = this.hiddenDataset.indexOf(dataName)

      if (index === -1) {
        this.hiddenDataset.push(dataName)
      } else {
        this.hiddenDataset.splice(index, 1)
      }
    },
    appendVisibility(data) {
      const name = data.name || data.label
      const visible = this.hiddenDataset.indexOf(name) === -1

      return { ...data, visible }
    }
  }
}
</script>
