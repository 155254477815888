<template>
  <div
    v-loading="loading"
    id="Analytics"
    class="tabcontent">
    <div class="card-main">
      <analytics-options-toolbar />
      <div class="chart-wrapper">
        <analytics-line-chart-section v-if="chartType === 'line'" />
        <analytics-bar-chart-section v-else-if="chartType === 'bar'" />
        <analytics-pie-chart-section v-else-if="chartType === 'pie'" />
        <analytics-table-section v-else-if="chartType === 'table'" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AnalyticsOptionsToolbar from '~/components/analytics/AnalyticsOptionsToolbar/AnalyticsOptionsToolbar'
import AnalyticsLineChartSection from '~/components/analytics/AnalyticsLineChartSection/AnalyticsLineChartSection'
import AnalyticsBarChartSection from '~/components/analytics/AnalyticsBarChartSection/AnalyticsBarChartSection'
import AnalyticsPieChartSection from '~/components/analytics/AnalyticsPieChartSection/AnalyticsPieChartSection'
import AnalyticsTableSection from '~/components/analytics/AnalyticsTableSection/AnalyticsTableSection'

export default {
  name: 'Analytics',
  middleware: ({ route, redirect }) => {
    if (route.fullPath.includes('/analytics')) {
      return redirect('/search?view=analytics')
    }
  },
  components: {
    AnalyticsTableSection,
    AnalyticsLineChartSection,
    AnalyticsBarChartSection,
    AnalyticsPieChartSection,
    AnalyticsOptionsToolbar
  },
  computed: {
    ...mapState('analytics', ['chartType']),
    ...mapState('search', ['isComponentLoading']),
    ...mapState('userSubscriptions', ['userHasAnalyticsSubscription']),
    loading() {
      return (
        (this.$actions.pending('analytics/fetchAnalyticsData') ||
          this.$actions.pending('search/fetchShipmentGraph')) &&
        !this.isComponentLoading
      )
    }
  },
  watch: {
    userHasAnalyticsSubscription(newVal) {
      if (!newVal) {
        const redirectUrl =
          REDIRECT_URLS.UPGRADE_SUBSCRIPTION_PLACEHOLDER /*eslint-disable-line no-undef */

        // remove this logic once placeholder is absolute to the application
        if (redirectUrl.indexOf('http') >= 0) {
          this.$router.go(redirectUrl)

          return
        }

        this.$router.replace(redirectUrl)
      }
    }
  }
}
</script>
<style src="@/assets/scss/sections/analytics/analytics.scss" lang="scss" />
