<template>
  <div 
    v-if="total > 0" 
    class="analytics-chart-pagination">
    <div class="display-text">
      <span id="analytics_pagination_label">
        Displaying <strong>{{ displayText }}</strong> of {{ field }}s
      </span>
    </div>

    <div 
      v-if="totalPages > 1" 
      class="pagination-buttons-wrapper">
      <button
        :class="{ disabled: page === 1 }"
        class="btn btn-text secondary"
        @click="page = page - 1">
        PREV
      </button>
      <button
        :class="{ disabled: page === totalPages }"
        class="btn btn-text secondary"
        @click="page = page + 1">
        NEXT 10
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnalyticsChartPagination',
  props: {
    ticksCount: {
      type: Number,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    }
  },
  computed: {
    page: {
      get() {
        return this.value
      },
      set(page) {
        this.$emit('input', page)
      }
    },
    displayText() {
      const dataCount = this.total
      if (dataCount < this.ticksCount) {
        return `1-${dataCount}`
      }
      const start = (this.page - 1) * this.ticksCount + 1
      let end = start + this.ticksCount - 1
      end = end < dataCount ? end : dataCount
      return `${start}-${end} of ${dataCount}`
    },
    field() {
      return this.$store.getters['analytics/fieldLabel']
    },
    totalPages() {
      return Math.ceil(this.total / this.ticksCount)
    }
  }
}
</script>
