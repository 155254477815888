<template>
  <ul
    :style="{'padding-left': padding, 'padding-right': padding}"
    class="analytics-bar-chart__labels">
    <li
      v-for="(label, index) in data"
      :key="index"
      :style="`width: ${width}`"
      @mouseenter="handleMouseEnter($event, label)"
      @mouseleave="handleMouseLeave">
      <nuxt-link
        v-if="label.id"
        :to="`/company/${label.id}/overview`"
        v-html="`${label.name}`"/>
      <span v-else>{{ label.name }}</span>
    </li>
  </ul>
</template>

<script>
import dom from '~/utils/dom'
import canvasTooltip from '~/utils/canvasTooltip'
import { convertToTwoDigitDecimal } from '~/utils/number'

export default {
  name: 'AnalyticsBarChartLabels',
  props: {
    data: {
      type: Array,
      required: true
    },
    layout: {
      type: Object,
      required: true
    }
  },
  computed: {
    width() {
      return `${100 / this.data.length}%`
      // const width = this.layout.width || 0
      // return width + 'px'
    },
    padding() {
      const padding = this.layout.padding | 0
      return padding + 'px'
    },
    measurementTooltipText() {
      const { measurement } = this.$store.state.analytics
      switch (measurement) {
        case 'Gross Weight (kg)':
          return 'kg'
        case 'calculated_teu':
          return 'TEU'
        default: {
          return (
            measurement.charAt(0).toUpperCase() +
            measurement.substr(1).toLowerCase()
          )
        }
      }
    }
  },
  methods: {
    handleMouseLeave() {
      canvasTooltip.clear()
    },
    handleMouseEnter(event, label) {
      const { y, x, height, width } = event.target.getBoundingClientRect()
      const title = label.name
      const body = `<b>${convertToTwoDigitDecimal(label.value)}</b> ${
        this.measurementTooltipText
      }`
      const placement = 'bottom'
      const position = {
        x: x + width / 2 + dom.getScrollX(),
        y: y + dom.getScrollY() + height + 4
      }
      canvasTooltip.setTooltip({ title, body, position, placement })
    }
  }
}
</script>
