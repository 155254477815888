import { merge } from 'lodash'
import canvasTooltip from '~/utils/canvasTooltip'
import { convertToTwoDigitDecimal } from '~/utils/number'
import { chart } from '~/config/config'
import dom from '~/utils/dom'

/**
 * Analytics Chart Base Mixins
 * Requires `chartData` and `chartOptions` computed or data property.
 * Set chartOptions.labelTooltip = true for label tooltips
 * @param {array} data - An array of analyticsData.
 * @param {string} x - The prop in analyticsData used for X-Value.
 * @param {string} y - The prop in analyticsData used for Y-Value.
 * @param {object} options - Chart.js options.
 */
export default {
  created() {
    if (!this.chartData && !this._props.chartData) {
      throw new Error('chartData (computed|data|props) value is required')
    }
    if (!this.chartOptions && !this._props.chartOptions) {
      throw new Error('chartOptions (computed|data|props) value is required')
    }
  },
  mounted() {
    canvasTooltip.initialize()
    this.renderChart(this.chartData, this.options)
  },
  destroyed() {
    canvasTooltip.destroy()
  },
  computed: {
    field() {
      return this.$store.state.analytics.field
    },
    measurement() {
      return this.$store.state.analytics.measurement
    },
    options() {
      const self = this
      const options = {
        tooltips: this.tooltips,
        legend: { display: false },
        defaultFontFamily: 'Overpass',
        title: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          onComplete() {
            // Make sure data is populated because data.length === 0 still runs onComplete
            const { data } = self.chartData.datasets[0]
            if (data.length === 0) {
              return
            }
            self.isRendered = true
            this.options.animation.onComplete = undefined
          },
          duration: chart.options.animation.duration
        }
      }

      const { chartOptions } = self
      if (chartOptions.scales) {
        let { xAxes, yAxes } = chartOptions.scales
        if (xAxes) {
          chartOptions.scales.xAxes = xAxes.map(xAxis =>
            merge({}, chart.xAxes, xAxis)
          )
        }
        if (yAxes) {
          chartOptions.scales.yAxes = yAxes.map(xAxis =>
            merge({}, chart.yAxes, xAxis)
          )
        }
      }
      // Show tooltip on x-axis label hover
      if (chartOptions.labelTooltip) {
        options.onHover = this.showTooltipOnXaxisHover.bind(this)
      }

      return merge({}, chartOptions, options)
    },
    yValuesSum() {
      return this.chartData.data.reduce((sum, item) => {
        return sum + item
      }, 0)
    },
    tooltips() {
      const {
        chartData,
        getTooltipBody,
        getPieChartAllOthersTooltipBody,
        setTooltip
      } = this
      const xLabels = chartData.labels
      return {
        mode: 'index',
        enabled: false,
        async custom(tooltipModel) {
          if (tooltipModel.opacity === 0 || !tooltipModel.body) {
            return canvasTooltip.clear()
          }

          const labelIndex = tooltipModel.dataPoints[0].index
          const title = xLabels[labelIndex]
          let body = tooltipModel.body.map(item => item.lines)[0][0]

          // Pie charts don't have titles
          if (tooltipModel.title.length < 1) {
            if (title.toLowerCase().indexOf('all other') === 0) {
              // Handle Pie chart's 'All Other...' label
              body = getPieChartAllOthersTooltipBody()
            } else {
              // Handle Pie chart's labels
              body = getTooltipBody(parseFloat(body.split(':')[1].trim()), true)
            }
          } else {
            // Handle bar chart
            body = getTooltipBody(body)
          }

          setTooltip({
            title,
            body,
            placement: 'top',
            x: tooltipModel.caretX,
            y: tooltipModel.caretY
          })
        }
      }
    }
  },
  watch: {
    chartData() {
      this.$data._chart.destroy() // eslint-disable-line
      this.renderChart(this.chartData, this.options)
    }
  },
  data() {
    return {
      tooltipId: 'analytics-tooltip',
      isRendered: false
      // height: 400
    }
  },
  methods: {
    showTooltipOnXaxisHover(event, chartElements) {
      if (chartElements.length > 0) {
        return
      }
      const { _chart: chart } = this.$data
      // Find x-axis.label intersecting element
      const [element] = chart.constructor.Interaction.modes.x(chart, event, {
        intersect: false
      })

      if (!element) {
        return canvasTooltip.clear()
      }

      // Check if mouse event is within x-axis bounds (not inside graph)
      const { _xScale: xAxis } = element
      const eventY = event.offsetY || event.layerY
      if (xAxis.top > eventY || eventY > xAxis.bottom) {
        return canvasTooltip.clear()
      }

      if (canvasTooltip.getIsOpen()) {
        return
      }

      const value = this.chartData.datasets[element._datasetIndex].data[
        element._index
      ]
      this.setTooltip({
        title: element._model.label,
        body: this.getTooltipBody(value),
        x: element._model.x,
        y: element._xScale.bottom,
        placement: 'bottom'
      })
    },
    setTooltip({ title, body, x, y, placement }) {
      const canvas = this.$data._chart.canvas.getBoundingClientRect()
      const position = {
        x: canvas.left + dom.getScrollX() + x,
        y: canvas.top + dom.getScrollY() + y
      }
      canvasTooltip.setTooltip({ title, body, position, placement })
    },
    getPieChartAllOthersTooltipBody() {
      const getListItemString = ({ label, percent, value }) => {
        let fixedPercent = Math.round(percent * 100)
        fixedPercent = fixedPercent > 0 ? fixedPercent : '<1'
        const formattedValue = convertToTwoDigitDecimal(value)
        return `<tr><td>${label}</td><td class="right">${fixedPercent}%</td><td class="right">${formattedValue}</td></tr>`
      }

      const list = this.chartData.others.slice(0, 5).map(getListItemString)

      if (this.chartData.others.length > 5) {
        const label = 'And Various Others'
        const { percent, value } = this.chartData.others.slice(5).reduce(
          (total, { value, percent }) => {
            total.percent += percent
            total.value += value
            return total
          },
          { percent: 0, value: 0 }
        )

        list.push(getListItemString({ label, percent, value }))
      }

      return `<table class="pie-chart-others"><tbody>${list.join(
        ''
      )}</tbody></table>`
    },
    getTooltipBody(value, isPieChart) {
      if (!isPieChart) {
        return `<b>${convertToTwoDigitDecimal(
          value
        )}</b> ${this.getMeasurementTooltipText()}`
      }

      const percent = Math.round((value / this.yValuesSum) * 100)
      const valueText = convertToTwoDigitDecimal(value)
      return `<b>${percent}%</b> ${valueText} ${this.getMeasurementTooltipText()}`
    },
    getMeasurementTooltipText() {
      const { measurement } = this
      switch (measurement) {
        case 'Gross Weight (kg)':
          return 'kg'
        case 'calculated_teu':
          return 'TEU'
        default: {
          return (
            measurement.charAt(0).toUpperCase() +
            measurement.substr(1).toLowerCase()
          )
        }
      }
    }
  }
}
