<script>
import { Pie } from 'vue-chartjs'
import analyticsChartMixin from '../../analyticsChartMixin.js'
import canvasTooltip from '~/utils/canvasTooltip'

let TweenLite

export default {
  name: 'AnalyticsPieChart',
  extends: Pie,
  mixins: [analyticsChartMixin],
  props: {
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeElements: [],
      hoveredElement: null,
      tooltipTween: null
    }
  },
  computed: {
    chartOptions() {
      const self = this
      const padding = 20

      return {
        layout: {
          padding: {
            top: padding,
            bottom: padding,
            left: padding,
            right: padding
          }
        },
        pieceLabel: {
          render({ percentage }) {
            return percentage + '%'
          },
          fontColor: 'white',
          fontSize: 10,
          fontStyle: 'bold'
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        onHover(event, elements) {
          if (!self.isRendered || elements[0] === self.activeElements[0]) {
            return
          }

          // Check if mouse is hovering over the empty area between slice and center
          if (self.activeElements.length > 0 && elements.length === 0) {
            const [element] = self.activeElements
            let { top, bottom, right, left } = element._chart.chartArea
            const chartCenter = {
              x: (left + right) / 2,
              y: (top + bottom) / 2
            }

            const { offsetX: x, offsetY: y } = event
            const distance = Math.sqrt(
              Math.pow(x - chartCenter.x, 2) + Math.pow(y - chartCenter.y, 2)
            )

            if (distance < element._model.outerRadius) {
              return
            }
          }

          self.activeElements = elements
        }
      }
    }
  },
  watch: {
    activeElements(newElements, oldElements) {
      oldElements.forEach(element => {
        const { top, bottom, right, left } = element._chart.chartArea
        const chartCenter = {
          x: (left + right) / 2,
          y: (top + bottom) / 2
        }
        TweenLite.to([element._view], 0.5, chartCenter)
        canvasTooltip.translateTo(element.__tooltip)
      })

      newElements.forEach(element => {
        element.__tooltip = { x: canvasTooltip.x, y: canvasTooltip.y }
        const { startAngle, endAngle, outerRadius } = element._view
        const radians = (startAngle + endAngle) / 2
        const translationDistance = outerRadius / (Math.PI * Math.PI)
        const xDiff = translationDistance * Math.cos(radians)
        const yDiff = translationDistance * Math.sin(radians)
        TweenLite.to([element._view], 0.5, {
          x: element._view.x + xDiff,
          y: element._view.y + yDiff
        })

        canvasTooltip.translateTo({
          x: canvasTooltip.x + xDiff,
          y: canvasTooltip.y + yDiff
        })
      })
    }
  },
  beforeMount() {
    TweenLite = require('gsap/TweenLite').default
    this.addPlugin(require('chart.piecelabel.js'))
  }
}
</script>
