<template>
  <div class="chart-container table-chart">
    <div class="chart-head">
      <h2>{{ chartTitle }}</h2>
    </div>

    <div class="chart-main"><analytics-table /></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AnalyticsTable from './AnalyticsTable'

export default {
  name: 'AnalyticsTableSection',
  components: { AnalyticsTable },
  computed: {
    ...mapGetters('analytics', ['chartTitle']),
    isResultsClean() {
      return this.$store.state.analytics.isResultsClean
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
