<template>
  <div :class="`${$store.state.dataViewer.viewType}-view`">
    <data-table
      :headers="headers"
      :data="analyticsData"
      :start-index="0"
      :page-size="50"
      :current-page="1"
      :total="analyticsData.length"
      :sort-field="''"
      :sort-order="''"
      :parent-name="parentName"
      :floating-scrollbar-options="floatingScrollbarOptions"/>
  </div>
</template>

<script>
import DataTable from '~/components/data-viewers/DataViewer/DataTable'
import { convertToTwoDigitDecimal } from '~/utils/number'

export default {
  name: 'AnalyticsTable',
  components: {
    DataTable
  },
  props: {
    parentName: {
      type: String,
      default() {
        return this.$options.name
      }
    }
  },
  data() {
    return {
      scrolling: false,
      floatingScrollbarOptions: {
        zIndex: 2,
        offsetBottom: 0
      }
    }
  },
  computed: {
    cleanedAnalyticsData() {
      return this.$store.getters['analytics/cleanedAnalyticsData']
    },
    fieldLabel() {
      return this.$store.getters['analytics/fieldLabel']
    },
    headers() {
      return [
        { id: 'index', text: '#' },
        { id: 'name', text: this.fieldLabel },
        { id: 'shipmentsCount', text: 'Shipments' },
        { id: 'containersCount', text: 'Containers' },
        { id: 'weightKg', text: 'Gross Weight (Kg)' },
        { id: 'calculatedTeu', text: 'TEU' }
      ]
    },
    analyticsData() {
      return this.cleanedAnalyticsData.map((analytics, index) => {
        const data = this.headers.map(header => {
          let { id } = header
          let link, className
          let text = analytics[id]

          switch (id) {
            case 'index':
              text = index + 1
              break
            case 'name':
              if (
                'consignee (importer),shipper (supplier)'.includes(
                  this.fieldLabel.toLowerCase()
                )
              ) {
                link = `/company/${analytics.id}/overview`
                className = `p-name`
              }
              break
            default:
              text = convertToTwoDigitDecimal(text).toString()
          }

          const data = { text }
          if (link) data.link = link
          if (className) data.class = className
          return data
        })
        return { data }
      })
    }
  },
  methods: {
    handleScroll(e) {
      this.scrolling = e.target.scrollTop > 0
    }
  }
}
</script>
