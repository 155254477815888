<template>
  <div
    class="chart-container">
    
    <div class="chart-head">
      <h2>{{ chartTitle }}</h2>
    </div>

    <div class="chart-main"><analytics-pie-chart-card /></div>
    
    <analytics-table/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AnalyticsPieChartCard from './AnalyticsPieChartCard/AnalyticsPieChartCard'
import AnalyticsTable from '~/components/analytics/AnalyticsTableSection/AnalyticsTable'

export default {
  name: 'AnalyticsPieChartSection',
  components: {
    AnalyticsPieChartCard,
    AnalyticsTable
  },
  computed: {
    ...mapGetters('analytics', ['chartTitle'])
  }
}
</script>
