<template>
  <div class="line-chart-legend">
    <ul>
      <li
        v-for="(item, $index) in legend"
        :key="$index"
        @click="$emit('select', item.label)">
        <div
          :style="{ cursor:'pointer', 'text-decoration': item.visible ? 'none' : 'line-through' }"
          class="label-wrapper"
          @click="$emit('toggle-data', item.label)">
          <span
            :style="{ 'background-color': item.color}"
            class="color-block"/>
          <span
            class="label">
            {{ item.label }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'LineChartLegend',
  props: {
    legend: {
      type: Array,
      required: true
    }
  }
}
</script>
