<template>
  <div class="analytic-control-options bottom-shadow">
    <div class="graphs-container">
      <h2>Choose Graph Type</h2>
      <div class="graph-options">
        <el-tooltip
          v-for="(value, key) in chartTypeOptions"
          :key="key"
          placement="bottom"
          popper-class="is-light">
          <template slot="content">
            <h3 style="margin: 0px">{{ value }}</h3>
          </template>
          <button
            :class="{ active: key === $store.state.analytics.chartType }"
            @click="$store.commit('analytics/setChartType', key)">
            <i :class="`${value.split(' ').join('-')}-icon`" />
          </button>
        </el-tooltip>
      </div>
    </div>

    <div class="analytics-controls">
      <fieldset
        v-for="(item, index) in fieldsets"
        v-show="item.show"
        :key="index">
        <h2>{{ item.title }}</h2>

        <select-options
          v-model="_self[item.model]"
          :options="item.options"/>
      </fieldset>
      <fieldset>
        <el-checkbox v-model="isResultsClean">
          <i>
            Remove blank entries
          </i>
        </el-checkbox>
        <hide-incomplete-data-checkbox v-if="chartType === 'line'"/>
        <autoscale-axis v-if="chartType === 'line'"/>
      </fieldset>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import SelectOptions from './SelectOptions'
import HideIncompleteDataCheckbox from '../../search/HideIncompletedataCheckbox'
import AutoscaleAxis from '~/components/search/AutoscaleAxis'

export default {
  name: 'AnalyticsOptionsToolbar',
  components: {
    SelectOptions,
    HideIncompleteDataCheckbox,
    AutoscaleAxis
  },
  data() {
    return {
      selectedMeasurement: this.$store.state.analytics.measurement,
      selectedField: this.$store.state.analytics.field,
      lineChartField: this.$store.state.analytics.lineChartField
    }
  },
  computed: {
    ...mapState('analytics', [
      'chartType',
      'chartTypeOptions',
      'measurementOptions',
      'fieldOptions'
    ]),
    fieldsets() {
      let fields = { ...this.fieldOptions }
      let model = 'lineChartField'

      if (this.chartType !== 'line') {
        delete fields.none
        model = 'selectedField'
      }

      return [
        {
          title: 'Field Analyzed',
          options: fields,
          model,
          show: true
        },
        {
          title: 'Measurement',
          options: this.measurementOptions,
          model: 'selectedMeasurement',
          show: true
        }
      ]
    },
    selections() {
      const field = this.selectedField
      const lineField = this.lineChartField
      const measurement = this.selectedMeasurement

      return { field, measurement, lineField }
    },
    isResultsClean: {
      get() {
        return this.$store.state.analytics.isResultsClean
      },
      set() {
        this.$store.commit('analytics/toggleCleanResults')
      }
    }
  },
  watch: {
    selections(newSelections, oldSelections) {
      const { lineField } = newSelections
      const isLineChart = this.chartType === 'line'

      if (!isLineChart) {
        this.lineChartField = this.selectedField
      }

      if (isLineChart && lineField !== 'none') {
        this.selectedField = this.lineChartField
      }

      const action = this.getActionType(newSelections, oldSelections)

      this.$store.dispatch('analytics/fetchAnalyticsData', {
        ...newSelections,
        action
      })
    }
  },
  methods: {
    getActionType(newSelections, oldSelections) {
      const { field: newField, lineField: newLineField } = newSelections
      const { field: oldField, lineField: oldLineField } = oldSelections

      if (oldField !== newField || oldLineField !== newLineField) {
        return 'changeAnalyzedField'
      }

      return 'changeMeasurement'
    }
  }
}
</script>
<style
  src="@/assets/scss/sections/analytics/analytics-options-toolbar.scss"
  lang="scss"
/>
