<template>
  <multiselect
    :value="value"
    :options="optionsList"
    :searchable="false"
    :show-labels="false"
    :allow-empty="false"
    label="label"
    track-by="value"
    class="custom-select"
    @input="select">
    <template
      slot="singleLabel"
      slot-scope="props">
      <span>{{ options[props.option] }}</span>
    </template>
  </multiselect>
</template>

<script>
/**
 * select-options
 * @param {any} v-model - The selected value
 * @param {Array} options - An array of options as primitives, or object with { label, value }.
 *        label prop is used to display in options list, and value is emited on select.
 */
export default {
  name: 'SelectOptions',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, Boolean, Array, Object, Date, Symbol],
      required: true
    },
    options: {
      type: Object,
      required: true
    }
  },
  computed: {
    optionsList() {
      return Object.keys(this.options)
        .map(key => ({
          value: key,
          label: this.options[key]
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1))
    }
  },
  methods: {
    select(option) {
      this.$emit('input', option.value)
    }
  }
}
</script>
